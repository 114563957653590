import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/4.jpg'; // Adjust the path as needed
import '../styles/Data.css'; // Don't forget to import the CSS file
import logo from '../assets/logo1.png';
import clientImg1 from '../assets/cnpc.png';
import clientImg2 from '../assets/spgc.png';
import clientImg3 from '../assets/lh.png';
import clientImg4 from '../assets/nioc.png';
import clientImg5 from '../assets/is.png';
import clientImg6 from '../assets/eni.png';
import clientImg7 from '../assets/am.png';
import clientImg8 from '../assets/total.png';

const Data = () => {
  return (
    <>
      <div className="data-container">
 
          <div className="flex-container1">
            <div className="column">
            <img src={logo} alt="" className='lean-logo' />
              <p style={{textAlign: 'center'}}>Our success is fully dependent on people. Priority one is to serve our global families; our tenured employees, multi-national partners, and surrounding communities. Our unique, close-knit culture and shared, long-term outlook is not only paramount to our success, but to the success of all integral stakeholders.</p>
            </div>
            <div className="contact1">
              <h3>Contact Us</h3>
              <p>
             <span style={{ fontWeight: 'bold' }}> AL WAFEEQA DMCC MAIN OFFICE</span><br />
             <br />
             
             P.O Box 334377,  Office No 2901<br />
              
              Cluster T, Jumeirah Lake Tower, DUBAI, UAE<br />
              <br />  Phone: +971 55 714 2510
              </p>
            </div>
            <div className="column news">
            <h3>Prominent Global Clients</h3>
            <div className="client-images">
              <img src={clientImg1} alt="Client 1" />
              <img src={clientImg2} alt="Client 2" />
              <img src={clientImg3} alt="Client 3" />
              <img src={clientImg4} alt="Client 4" />
              <img src={clientImg5} alt="Client 5" />
              <img src={clientImg6} alt="Client 6" />
              <img src={clientImg7} alt="Client 7" />
              <img src={clientImg8} alt="Client 8" />
            </div>
          </div>
            <div className="column careers">
              <h3>Careers</h3>
              <a href="./careers" className="link-style">Careers at Al Wafeeqa DMCC</a>


              <h3>Connect</h3>
              <ul>
                <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="link-style">LinkedIn</a></li>
                <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="link-style">Facebook</a></li>
                <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="link-style">Twitter</a></li>
              </ul>
              <h3>Customers</h3>
              <ul>
                <li>Make Payment</li>
                <li>Privacy Policy</li>
                <li>Terms and Conditions</li>
              </ul>
            </div>
          </div>
          
        
      </div>
    </>
  );
};

export default Data;