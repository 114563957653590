// import React from 'react';
// import { Link } from "react-router-dom";
// import { Carousel } from 'react-responsive-carousel';
// import img1 from '../assets/4.jpg';
// import img2 from '../assets/1.jpg';
// import img3 from '../assets/2.jpg';
// import img4 from '../assets/3.jpg';
// import img5 from '../assets/logo.png';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

// import '../styles/Home.css'; // Import your custom CSS for styling

// const Home = () => {
//   return (
//     <div className="home-container">
//       <MyCarousel />
//       <div className="container">
       
//         <div className="grid-container">
//           <div className="box culinary">
//             <img src={img1} alt="Culinary" className="image" />
//             <div className="box-content">
//               <h2 className="heading-text">MINING & INDUSTRIAL</h2>
//               <p className="description">
//                 In 1951, H. William Derrick Jr. invented the first high-speed screen vibrator. Through this successful innovation, he paved Derrick’s path into the Mining & Industrial market. Derrick continues to develop and manufacture an array of wet and dry, fine particle separation technologies that result in increased product recovery and reduced power consumption.
//               </p>
//               <Link to="/studio" className="link">VIEW DETAILS</Link>
//             </div>
//           </div>
//           <div className="box art">
//             <img src={img2} alt="Art" className="image" />
//             <div className="box-content">
//               <h2 className="heading-text">OIL & GAS DRILLING</h2>
//               <p className="description">
//                 In 1977, we challenged ourselves further by expanding into the Oil & Gas Drilling industry. Our solutions symbolize rugged reliability in an industry known for its intensely hazardous environment and ever-changing drilling technologies. Derrick now offers the most comprehensive and cost-effective solids control systems in the industry.
//               </p>
//               <Link to="/studio" className="link">VIEW DETAILS</Link>
//             </div>
//           </div>
//           <div className="box graphics-design">
//             <img src={img3} alt="Graphics Design" className="image" />
//             <div className="box-content">
//               <h2 className="heading-text">CIVIL CONSTRUCTION</h2>
//               <p className="description">
//                 In 1988, new technologies were pioneered and Derrick branched out into yet another industry. Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
//               </p>
//               <Link to="/studio" className="link">VIEW DETAILS</Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MyCarousel = () => (
//   <Carousel
//     autoPlay
//     infiniteLoop
//     interval={1000}
//     showStatus={false}
//     showThumbs={false}
//     showArrows={true}
//     className="custom-carousel"
//   >
//     <div className="carousel-slide">
//       <img src={img1} alt="Slide 1" className="carousel-image" />
//       <h2 className="carousel-heading">Welcome to Dota 2 Vertex</h2>
//     </div>
//     <div className="carousel-slide">
//       <img src={img2} alt="Slide 2" className="carousel-image" />
//       <h2 className="carousel-heading">Immerse Yourself in the World of Dota 2:</h2>
//     </div>
//     <div className="carousel-slide">
//       <img src={img3} alt="Slide 3" className="carousel-image" />
//       <h2 className="carousel-heading">Watch Your Favourite Heroes</h2>
//     </div>
//     <div className="carousel-slide">
//       <img src={img4} alt="Slide 4" className="carousel-image" />
//       <h2 className="carousel-heading">Night life is cool</h2>
//     </div>
//   </Carousel>
// );

// export default Home;


// Home.jsx

import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../assets/4.jpg';
import img2 from '../assets/1.jpg';
import img3 from '../assets/2.jpg';
import img4 from '../assets/3.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Home.css'; // Import your custom CSS for styling
import Data from './Data';

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const boxes = container.querySelectorAll('.box');

    const handleScroll = () => {
      boxes.forEach(box => {
        const boxTop = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (boxTop < windowHeight * 0.8) {
          box.classList.add('show');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <>
    <div className="home-container">
      <MyCarousel />
      <div className="container" ref={containerRef}>
        <div className="grid-container">
          <div className="box culinary">
            <img src={img1} alt="Culinary" className="image" />
            <div className="box-content">
              <h2 className="heading-text">MINING & INDUSTRIAL</h2>
              <p className="description" style={{textAlign: 'center'}}>
                Through this successful innovation, he paved Derrick’s path into the Mining & Industrial market. Derrick continues to develop and manufacture an array of wet and dry, fine particle separation technologies that result in increased product recovery and reduced power consumption.
              </p>
              <Link to="/studio" className="link">VIEW DETAILS</Link>
            </div>
          </div>
          <div className="box art">
            <img src={img2} alt="Art" className="image" />
            <div className="box-content">
              <h2 className="heading-text">OIL & GAS DRILLING</h2>
              <p className="description" style={{textAlign: 'center'}}>
                Our solutions symbolize rugged reliability in an industry known for its intensely hazardous environment and ever-changing drilling technologies. Derrick now offers the most comprehensive and cost-effective solids control systems in the industry.
              </p>
              <Link to="/studio" className="link">VIEW DETAILS</Link>
            </div>
          </div>
          <div className="box graphics-design">
            <img src={img3} alt="Graphics Design" className="image" />
            <div className="box-content">
              <h2 className="heading-text">CIVIL CONSTRUCTION</h2>
              <p className="description" style={{textAlign: 'center'}}>
                Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
              </p>
              <Link to="/studio" className="link">VIEW DETAILS</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="data-container">
    <div className="top-row">
          <h2 className="data-text" style={{textAlign: 'center'}}>We not only have the right technology and expert resources but also have a deeper understanding of problems specific to your process.</h2>
        </div>
        <div className="bottom-row">
        <img src={img1} alt="" className="data-image" />
        </div>
        </div>
    </>;
};

const MyCarousel = () => (
  <Carousel
      autoPlay
      infiniteLoop
      interval={1000}
      showStatus={false}
      showThumbs={false}
      showArrows={true}
      className="custom-carousel"
    >
      <div className="carousel-slide">
        <img src={img1} alt="Slide 1" className="carousel-image" />
        
      </div>
      <div className="carousel-slide">
        <img src={img2} alt="Slide 2" className="carousel-image" />
       
      </div>
      <div className="carousel-slide">
        <img src={img3} alt="Slide 3" className="carousel-image" />
        
      </div>
      <div className="carousel-slide">
        <img src={img4} alt="Slide 4" className="carousel-image" />
       
      </div>
    </Carousel>
);

export default Home;
