import React from 'react';
import "../styles/Header1.css";
import { Link } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';

const Header1 = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' ,boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
      
     <nav> <Link to='/' className='heading1'>AL WAFEEQA DMCC</Link>
     </nav>
      <nav>
        
        <Link to="./about">AlWafeeqaDMCC</Link>
        <Link to="./contact">Contact Us</Link>
        <Link to="./careers" style={{ fontWeight: 'bold' }}>Careers</Link>
        <Link><FaSearch /></Link>
      </nav>
    </div>
  );
}

export default Header1;
