import React from 'react';
import '../styles/Contact.css'; // Import your CSS file for styling

const Contact = () => {
  return <>
    <div className="contact-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
     
      <div className="content">CONTACT</div>
        <div className="navigation">
        
          <span>HOME</span> / <span className='contact'>CONTACT</span>
        
      </div>
      
    </div>

    <div className="office-info">
          <h2>AL WAQEEFA DMCC MAIN OFFICE</h2>
          <div className="office">
            
            <p>P.O Box 334377, Office No 2901</p>
            <p>Cluster T, Jumeirah Lake Tower, DUBAI, UAE</p>
            <p>Phone: +971 55 714 2510</p>
          </div>
         
        </div>
    
    </>;
}

export default Contact;
