// import React from 'react';
// import { AiOutlineSend } from 'react-icons/ai';
// import { FaLinkedin, FaGithub, FaReddit } from 'react-icons/fa';

// const Footer = () => {
//   return (
//     <footer style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', minHeight: '40px', padding: '16px', color: 'white' }}>
//       <div style={{ display: 'flex', flexDirection: ['column', 'row'] }}>

//         <div style={{ flex: 1, paddingRight: ['0', '4px'] }}>
//           <h3 style={{ textTransform: 'uppercase', textAlign: ['center', 'left'], marginBottom: '8px' }}>Subscribe to the Newsletter</h3>
//           <div style={{ display: 'flex', borderBottom: '2px solid white', padding: '2px 0' }}>
//             <input style={{ flex: 1, border: 'none', borderRadius: 'none', outline: 'none', backgroundColor: 'transparent', color: 'white' }} placeholder="Enter Email Here..." />
//             <button style={{ padding: '0', color: '#805ad5', backgroundColor: 'transparent', border: 'none', borderRadius: '0 20px 20px 0' }}>
//               <AiOutlineSend size={20} />
//             </button>
//           </div>
//         </div>

//         <div style={{ flex: 1, borderLeft: ['none', '1px solid white'], borderRight: ['none', '1px solid white'], padding: ['16px 0', '0 16px'] }}>
//           <h3 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '8px' }}>DOTA 2 VERTEX</h3>
//           <p>All Rights Reserved</p>
//         </div>

//         <div style={{ flex: 1, padding: ['16px 0', '0 16px'] }}>
//           <h3 style={{ textTransform: 'uppercase', marginBottom: '8px' }}>Social Media</h3>
//           <div style={{ display: 'flex' }}>
//             <button style={{ color: '#0a66c2', backgroundColor: 'transparent', border: 'none' }}>
//               <a href="https://www.linkedin.com" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
//               <FaLinkedin />
              
//               </a>
//             </button>

//             <button style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}>
//               <a href="https://github.com" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
//               <FaGithub />
              
//               </a>
//             </button>

//             <button style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}>
//               <a href="https://www.reddit.com" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
//               <FaReddit />
             
//               </a>
//             </button>
//           </div>
//         </div>

//       </div>
//     </footer>
//   );
// }

// export default Footer;







// // import React from 'react';
// // import { FaInstagram, FaWhatsapp, FaTwitter } from 'react-icons/fa';
// // import { NavLink } from 'react-router-dom';

// // const Footer = () => {
  
// //   // Function to scroll to the top of the page
// //   const handleScrollToTop = () => {
// //     window.scrollTo(0, 0);
// //   };

// //   return (
// //     <footer style={{ backgroundColor: 'rgb(197, 138, 234)', color: 'white', width: '100%', height: '100vh', padding: ['0', '16px'], display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between' }}>
// //       <div style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>
// //         <h1 style={{ fontSize: '2em', fontFamily: 'WildKind', letterSpacing: '0.1em', textTransform: 'uppercase', textAlign: ['center', 'left'], marginBottom: '16px' }}>
// //           Ready to create <br />
// //           something awesome?
// //         </h1>
// //         <button style={{ backgroundColor: 'black', color: 'white', width: '150px', height: '45px', borderRadius: '50px', alignSelf: ['center', 'auto'], cursor: 'pointer', _hover: { color: 'white' } }}>
// //           GET IN TOUCH
// //         </button>
// //       </div>

// //       <div style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>
// //         <p>Umar Arshad</p>
// //         <NavLink to="/" onClick={handleScrollToTop} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>Home</NavLink>
// //         <NavLink to="/about" onClick={handleScrollToTop} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>About</NavLink>
// //         <NavLink to="/studio" onClick={handleScrollToTop} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>Studio</NavLink>
// //         <p>Portfolio</p>
// //         <NavLink to="/contact" onClick={handleScrollToTop} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>Contact</NavLink>
// //       </div>

// //       <div style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>
// //         <h3 style={{ textTransform: 'uppercase', marginBottom: '8px' }}>Social Media</h3>
// //         <div style={{ display: 'flex' }}>
// //           <button style={{ backgroundColor: 'rgb(240, 65, 35)', cursor: 'pointer' }}>
// //             <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
// //               <FaInstagram />
// //               Instagram
// //             </a>
// //           </button>

// //           <button style={{ cursor: 'pointer' }}>
// //             <a href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
// //               <FaWhatsapp />
// //               Whatsapp
// //             </a>
// //           </button>

// //           <button style={{ cursor: 'pointer' }}>
// //             <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
// //               <FaTwitter />
// //               Twitter
// //             </a>
// //           </button>
// //         </div>
// //       </div>

// //       <div style={{ display: 'flex', borderTop: '1px solid white', padding: '4px', justifyContent: 'space-between', width: '100%' }}>
// //         <h3 style={{ textTransform: 'uppercase' }}>UMAR ARSHAD</h3>
// //         <p>All Rights Reserved</p>
// //       </div>
// //     </footer>
// //   );
// // }

// // export default Footer;

import React from 'react';
import { FaMapMarkerAlt, FaLinkedin, FaFacebook,FaTwitter } from 'react-icons/fa';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-text">All rights reserved</div>
                <div className="footer-icons">
                    <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">
                        <FaMapMarkerAlt className="icon" />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className="icon" />
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="icon" />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="icon" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
