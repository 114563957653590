import React from 'react'

const Careers = () => {
  return <>
  <div className="contact-container">
    <div className="background-image"></div>
    <div className="overlay"></div>
   
    <div className="content">CAREERS</div>
      <div className="navigation">
      
        <span>HOME</span> / <span className='contact'>CAREERS</span>
      
    </div>
    
  </div>

  <div className="office-info">
        <h2>AL WAQEEFA DMCC</h2>
        <div className="office">
          
          
          <p>**No Openings at the moment**</p>
          
        </div>
       
      </div>
  
  </>;
}

export default Careers