import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './components/Header'
import Header1 from './components/Header1'
import Home from "./components/Home";
import Footer from "./components/Footer";
import About from './components/About'
import Contact from "./components/Contact";
import Data from "./components/Data";
import Careers from "./components/Careers";
import News from "./components/News";
import Products from "./components/Products";
import Market from "./components/Market";
import Oil from "./components/Oil";

function App() {
  return <Router>
    <Header1 />
    <Header />
    <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/about' element={<About />} />
    <Route path='/contact' element={<Contact />} />
    <Route path='/careers' element={<Careers />} />
    <Route path='/news' element={<News />} />
    <Route path='/products' element={<Products />} />
    <Route path='/market' element={<Market />} />
    <Route path='/oil' element={<Oil />} />
    </Routes>
    <Data />
    <Footer />

  </Router>
}

export default App;
