import React from 'react';
import '../styles/Contact.css'; // Import your CSS file for styling

const News = () => {
  return <>
    <div className="contact-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
     
      <div className="content">NEWS & EVENTS</div>
        <div className="navigation">
        
          <span>HOME</span> / <span className='contact'>NEWS & EVENTS</span>
        
      </div>
      
    </div>

    <div className="office-info">
        <h2>AL WAQEEFA DMCC</h2>
        <div className="office">
          
          
          <p>**No News & Events at the moment**</p>
          
        </div>
       
      </div>
    
    </>;
}

export default News;
