

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../assets/logo1.png';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={`header-container ${isSticky ? 'sticky' : ''}`}>
      <nav>
        <Link to="/">
          <img src={logo} alt="Lean Logo" />
        </Link>
      </nav>
      <nav>
        <Link to="./about">About AlWafeeqaDMCC</Link>
        <Link
          to="./oil"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          Markets Served
          {showDropdown && (
            <div className="dropdown-content">
              <Link to="./oil">Oil and Gas</Link>
              <Link to="#">Petrochemicals</Link>
              <Link to="#">Pumps, Engines & Spare Parts</Link>
              <Link to="#">Heavy Equipment & Machinery Spare Parts</Link>
            </div>
          )}
        </Link>
        <Link to="./products">Products</Link>
        <Link to="./news">News & Events</Link>
      </nav>
    </div>
  );
};

export default Header;
