// // import React from 'react';
// // import '../styles/Oil.css'; // Import your CSS file for styling

// // const Oil = () => {
// //   return <>
// //     <div className="contact-container">
// //       <div className="background-image"></div>
// //       <div className="overlay"></div>
     
// //       <div className="content">OIL & GAS DRILLING</div>
// //         <div className="navigation">
        
// //           <span>HOME</span> / <span className='contact'>OIL & GAS DRILLING</span>
        
// //       </div>
      
// //     </div>

// //     <div className="products-info">
// //           <h2>OIL & GAS DRILLING</h2>
         
         
// //         </div>
    
// //     </>;
// // }

// // export default Oil;



// import React from 'react';
// import '../styles/Oil.css'; // Import your CSS file for styling
// import img1 from '../assets/4.jpg'; // Import image for now

// const Oil = () => {
//   return (
//     <>
      // <div className="contact-container">
        // <div className="background-image"></div>
        // <div className="overlay"></div>
        // <div className="content">OIL & GAS DRILLING</div>
        // <div className="navigation">
        //   <span>HOME</span> / <span className='contact'>OIL & GAS DRILLING</span>
        // </div>
      // </div>

//       <div className="products-info">
//         <h2>OIL & GAS DRILLING</h2>
//         <div className="tabs-container">
//           <div className="tab">All Products</div>
//           <div className="tab">Degassers</div>
//           <div className="tab">Centrifuges</div>
//           <div className="tab">Shakers</div>
//           <div className="tab">Screens</div>
//           <div className="tab">Scalpers</div>
//         </div>
//         <div className="tab-content">
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 1" />
//               <p>Product 1</p>
//             </div>
//           </div>
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 2" />
//               <p>Product 2</p>
//             </div>
//           </div>
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 3" />
//               <p>Product 3</p>
//             </div>
//           </div>
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 4" />
//               <p>Product 4</p>
//             </div>
//           </div>
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 5" />
//               <p>Product 5</p>
//             </div>
//           </div>
//           <div className="tab-pane">
//             <div className="product">
//               <img src={img1} alt="Product 6" />
//               <p>Product 6</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Oil;


import React, { useState } from 'react';
import '../styles/Oil.css'; // Import your CSS file for styling
 // Import image for now
import img1 from '../assets/vfd.jpg';
import img2 from '../assets/vd.png';
import img3 from '../assets/uss.jpg';
import img4 from '../assets/ps.jpg';
import img5 from '../assets/hs.jpg';
import img6 from '../assets/gbd.jpg';
import img7 from '../assets/fyd.jpg';
import img8 from '../assets/flp2.jpg';
import img9 from '../assets/flp.jpg';
import img10 from '../assets/flc5.jpg';
import img11 from '../assets/flc4.jpg';
import img12 from '../assets/flc2.png';
import img13 from '../assets/fs.jpg';
import img14 from '../assets/dps6.jpg';
import img15 from '../assets/ad.jpg';
import img16 from '../assets/api.png';

const Oil = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState(0);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // Function to render product for each tab
  const renderProduct = (product) => {
    let productImage;
    switch (product) {
      case 'VARIABLE FREQUENCY DRIVE':
        productImage = img1;
        break;
      case 'VACUUM DEGASSER':
        productImage = img2;
        break;
      case 'URETHANE SCALPING SCREENS':
        productImage = img3;
        break;
      case 'PYRAMID SCREENS':
        productImage = img4;
        break;
      case 'Hyperpool Series':
        productImage = img5;
        break;
      case 'Gearbox Drive':
        productImage = img6;
        break;
      case 'Full Hydraulic Drive':
        productImage = img7;
        break;
      case 'Flo-Line Primer':
        productImage = img8;
        break;
      case 'Flo-Line Primer II':
        productImage = img9;
        break;
      case 'Flo-Line Cleaner 500':
        productImage = img10;
        break;
      case 'Flo-Line Cleaner 400':
        productImage = img11;
        break;
      case 'Flo-Line Cleaner 2000':
        productImage = img12;
        break;
      case 'Flat Screens':
        productImage = img13;
        break;
      case 'Dual Pool 600 Series':
        productImage = img14;
        break;
      case 'Atmospheric Degasser':
        productImage = img15;
        break;
      // Add cases for other products and their corresponding images
      case 'API RP 13C (ISO 13501) Compliant Screens':
        productImage = img16;
        break;
      default:
        productImage = img1; // Default to img1 if no match found
    }
    return (
      <div key={product} className="product">
        <img src={productImage} alt={product} />
        <p>{product}</p>
      </div>
    );
  };
  

  // Generate products for each tab
  const productsByTab = [
    ['VARIABLE FREQUENCY DRIVE', 'VACUUM DEGASSER', 'URETHANE SCALPING SCREENS', 'PYRAMID SCREENS', 'Hyperpool Series', 'Gearbox Drive', 'Full Hydraulic Drive Flo-Line Primer II', 'Flo-Line Primer', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Flat Screens', 'Dual Pool 600 Series', 'Atmospheric Degasser', 'API RP 13C (ISO 13501) Compliant Screens'],
    ['VACUUM DEGASSER', 'Atmospheric Degasser'],
    ['VARIABLE FREQUENCY DRIVE', 'Gearbox Drive', 'Full Hydraulic Drive'],
    ['Hyperpool Series', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Dual Pool 600 Series'],
    ['Urethane Scalping Screens', 'Pyramid Screens', 'Hyperpool Series', 'Flo-Line Primer II', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Flat Screens', 'Dual Pool 600 Series', 'API RP 13C (ISO 13501) Compliant Screens'],
    ['Flo-Line Primer II', 'Flo-Line Primer']
  ];

  return (
    <>
      <div className="contact-container">
      <div className="background-image"></div>
        <div className="overlay"></div>
        <div className="content">OIL & GAS DRILLING</div>
        <div className="navigation">
          <span>HOME</span> / <span className='contact'>OIL & GAS DRILLING</span>
        </div>
      </div>

      <div className="products-info">
        <h2>OIL & GAS DRILLING</h2>
        <div className="tabs-container">
          {['All Products', 'Degassers', 'Centrifuges', 'Shakers', 'Screens', 'Scalpers'].map((tabName, index) => (
            <div
              key={index}
              className={`tab ${index === activeTab ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tabName}
            </div>
          ))}
        </div>
        <div className="tab-content">
          {productsByTab[activeTab].map((product) => renderProduct(product))}
        </div>
      </div>
    </>
  );
}


export default Oil;
