import React from 'react';
import '../styles/Market.css'; // Import your CSS file for styling

const Market = () => {
  return <>
    <div className="contact-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
     
      <div className="content">CONTACT</div>
        <div className="navigation">
        
          <span>HOME</span> / <span className='contact'>CONTACT</span>
        
      </div>
      
    </div>

    <div className="office-info">
          <h2>AL WAQEEFA DMCC MAIN OFFICE</h2>
          <div className="office">
            
            <p>Office No 2901</p>
            <p>One Lake Plaza, Jumeirah Tower, DUBAI, UAE</p>
            <p>Phone: +971 505889062</p>
          </div>
         
        </div>
    
    </>;
}

export default Market;
